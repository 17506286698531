import Router from 'next/router'

export const setupFacebookPixel = async (eventId, window, document) => {
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', process.env.FACEBOOK_CAPI_DATASOURCE_ID);
    fbq('track', 'PageView', {}, { eventID: eventId });
    Router.events.on('routeChangeComplete', async () => {
        fbq('track', 'PageView', {}, { eventID: eventId });
    })
}
