import { postFacebookCapiPageView } from './api/facebookCapi'
import Router from 'next/router'

export const setupFacebookCapi = async (eventId: string) => {
  try {
    await postFacebookCapiPageView(eventId)
  } catch (_) {}

  Router.events.on('routeChangeComplete', async () => {
    try {
      await postFacebookCapiPageView(eventId)
    } catch (_) {}
  })
}
