import { css } from '@emotion/react'
import { Global } from '@emotion/react'
import { colors } from 'plume-ui'

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        a {
          cursor: pointer;
          text-decoration: none;
          transition-property: all;
          transition: all 0.2s ease 0s;
        }
        li {
          list-style: none;
        }
        ::selection {
          background-color: ${colors.cyan40};
        }
        .grecaptcha-badge {
          visibility: hidden;
        }

        #nprogress {
          .bar {
            background: #f72a48;
            position: fixed;
            z-index: 1031;
            top: 0;
            left: 0;

            width: 100%;
            height: 2px;
          }
          .peg {
            box-shadow: none !important;
          }
        }
      `}
    />
  )
}
