import { User, Role } from '../types'

export const createGuest = (): User => ({
  id: '',
  name: '',
  email: '',
  role: 'guest',
  tosAgreed: false,
  imageUrl: '',
  contractId: '0',
  contractName: '',
  contractPlan: 'tbd',
  earlyAccess: false,
  allowUserDeletion: false,
})

export const isGuest = (user: User | undefined | null): boolean =>
  !isLoggedIn(user)

const enterpriseRoles: Role[] = ['enterprise', 'moderator']

export const isEnterprise = (user: User | undefined | null): boolean =>
  user?.role ? enterpriseRoles.includes(user?.role) : false

const enterpriseWithTrialRoles: Role[] = ['trial', ...enterpriseRoles]

export const isEnterpriseWithTrial = (
  user: User | undefined | null
): boolean =>
  user?.role ? enterpriseWithTrialRoles.includes(user?.role) : false

const loginRoles: Role[] = ['member', ...enterpriseWithTrialRoles]

export const isLoggedIn = (user: User | undefined | null): boolean =>
  user?.role ? loginRoles.includes(user.role) : false

export const isTrial = (user: User | undefined | null): boolean =>
  user?.role === 'trial'

export const isModerator = (user: User | undefined | null): boolean =>
  user?.role === 'moderator'

export const isUserManager = (user: User | undefined | null): boolean =>
  user?.allowUserDeletion === true

const latestPlans = ['article_dl']

export const isLatestPlan = (user: User | undefined | null): boolean =>
  user?.contractPlan ? latestPlans.includes(user?.contractPlan) : false

export const isEarlyAccess = (user: User | undefined | null): boolean =>
  user?.earlyAccess === true

const UB_STAFF_EMAIL = /@(uzabase\.com|jvr\.jp)/

export const isUzabaseStaff = (user: User | undefined | null): boolean =>
  !!user?.email && UB_STAFF_EMAIL.test(user.email)

export const isLatestEnterpriseOrTrial = (
  user: User | undefined | null
): boolean => isTrial(user) || (isEnterprise(user) && isLatestPlan(user))
