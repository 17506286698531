import { User } from '../../types'
import { printError } from '../log'
import { createGuest } from '../user'
import { basePath, callAPI, callAPIRaw, callAPIWithNoContent } from './common'

export const getUser = (): Promise<User> => {
  return callAPI<User>(`${basePath}/users/me`, {
    method: 'GET',
    credentials: 'include',
  })
    .then((payload) => {
      return payload
    })
    .catch((err) => {
      printError(err)
      return createGuest()
    })
}

export type LoginPayload = {
  email: string
  password: string
  keep_login: boolean
}

export const login = (payload: LoginPayload) => {
  const body = JSON.stringify({
    user: payload,
  })
  return callAPIWithNoContent(`${basePath}/users/login`, {
    method: 'POST',
    body,
  })
}

export const loginWithJwt = async (accessToken: string) => {
  const url = `${basePath}/v2/users/npid_login`
  const { status, headers } = await callAPIRaw(url, {
    method: 'POST',
    headers: { 'X-NPID-ACCESS-TOKEN': accessToken, 'Content-length': '0' },
  })
  return { status, headers }
}

export const loginWithUbSso = async (accessToken: string) => {
  const url = `${basePath}/v2/users/ub_sso_login`
  const { status, headers } = await callAPIRaw(url, {
    method: 'POST',
    headers: {
      'X-UB-SSO-ACCESS-TOKEN': accessToken,
      'Content-length': '0',
    },
  })
  return { status, headers }
}

export const npidLogout = async () =>
  await callAPIRaw(`${basePath}/auth/logout`)
