import { css } from '@emotion/react'
import React from 'react'

const FullscreenWhiteOverlay: React.FC = () => {
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        z-index: 100000;
      `}
    />
  )
}

export default FullscreenWhiteOverlay
