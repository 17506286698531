import { useEffect, useState } from 'react'

type Props = {
  children: React.ReactNode
}

export const NoSsr: React.FC<Props> = ({ children }) => {
  const [isMounted, setMount] = useState(false)
  useEffect(() => {
    setMount(true)
  }, [])
  return <>{isMounted ? children : null}</>
}
