import Router from 'next/router'
import { colors } from 'plume-ui'
import { isLoggedIn, isEnterpriseWithTrial } from '@/lib/user'
import { User, IntercomUserData } from '../types'

declare global {
  interface Window {
    Intercom: any
  }
}

export const setupIntercom = (user: User, path: string) => {
  let userData: IntercomUserData = {
    app_id: process.env.INTERCOM_APP_ID as string,
    action_color: colors.black30,
    background_color: colors.black30,
  }
  if (process.env.INTERCOM_ENABLED && shouldInitIntercom(user)) {
    userData = {
      ...userData,
      user_id: user.id,
      name: user.name,
      email: user.email,
    }
    if (isEnterpriseWithTrial(user)) {
      userData = {
        ...userData,
        company: {
          company_id: user.contractId,
          name: user.contractName,
          plan: user.contractPlan,
        },
      }
    }

    window.Intercom('boot', userData)
    const timeout = setTimeout(() => clearInterval(interval), 30000)
    const interval = setInterval(() => {
      setIntercomVisibility(path)
      clearInterval(interval)
      clearTimeout(timeout)
    }, 100)

    Router.events.on('routeChangeComplete', (path: string) => {
      window.Intercom('update', {
        last_request_at: new Date().getTime() / 1000,
      })
      setIntercomVisibility(path)
    })
  }
}

const setIntercomVisibility = (path: string) => {
  if (path.startsWith('/enterprise')) {
    window.Intercom('update', {
      hide_default_launcher: true,
    })
  } else {
    window.Intercom('update', {
      hide_default_launcher: false,
    })
  }
}

const HIDDEN_USER_IDS = [9, 22415]
const shouldInitIntercom = (user: User) => {
  try {
    return isLoggedIn(user) && !HIDDEN_USER_IDS.includes(parseInt(user.id, 10))
  } catch (e) {
    // NOTE: Don't init Intercom if something wrong
    return false
  }
}
