export const isBrowser = !!(process as any).browser

/* Attribute Hack: IE9, IE10, IE11 */
export const IE9Plus = '@media screen and (min-width: 0\0)'

/* Attribute Hack: IE11 */
export const IE11 = '_:-ms-lang(x)::-ms-backdrop'

export const getBypassCode = () => {
  if (isBrowser) {
    const params = new URLSearchParams(location.search)
    return params.get('code')
  } else {
    return null
  }
}
