import { isProduction } from './env'

export const printError = (e: unknown) => {
  if (isProduction) {
    return
  }

  if (typeof window === 'undefined') {
    if (e === undefined || e === null) {
      console.error('e is nundefined or null in printError.')
      return
    }
    console.error(JSON.stringify(e, Object.getOwnPropertyNames(e)))
  } else {
    console.error(e)
  }
}
