import { User } from '../types'
import { userAdminUrl } from './url'

declare global {
  interface Window {
    Appcues: any
  }
}

export const setupAppcues = (user: User) => {
  const appcues = window.Appcues
  if (appcues) {
    window.Appcues.identify(user.id, {
      name: user.name,
      email: user.email,
      url: userAdminUrl(user.id),
      role: user.role,
      plan: user.contractPlan,
    })
  }
}
