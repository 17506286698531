import TagManager from 'react-gtm-module'
import { isEnterpriseWithTrial, isLoggedIn, isUzabaseStaff } from '@/lib/user'
import { User } from '../types'
import { EventType } from './tracking'
import { isProduction } from './env'

export const setupGoogleTagManager = (user: User) => {
  if (isProduction && process.env.GTM_ID) {
    let args: any = {
      gtmId: process.env.GTM_ID,
    }
    if (isLoggedIn(user)) {
      args = {
        ...args,
        dataLayer: {
          uid: user.id,
          ub_staff: isUzabaseStaff(user) ? 1 : 0,
        },
      }
    }
    TagManager.initialize(args)
  }
}

declare global {
  interface Window {
    dataLayer?: object[]
  }
}

export const pushEventToGTM = (
  user: User,
  eventName: EventType,
  value?: string | null
) => {
  if (isProduction && isEnterpriseWithTrial(user) && window.dataLayer) {
    window.dataLayer.push({
      event: 'event',
      eventProps: {
        category: 'tracking',
        action: eventName,
        label: null,
        value: value,
      },
      uid: user.id,
      ub_staff: isUzabaseStaff(user) ? 1 : 0,
    })
  }
}
