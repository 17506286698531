import { basePath, callAPIWithNoContent } from './common'

export const postFacebookCapiPageView = (eventId: string) => {
  const userAgent: string = navigator.userAgent
  const url: string = location.href

  return callAPIWithNoContent(`${basePath}/facebook_events/page_view`, {
    method: 'POST',
    body: JSON.stringify({
      user_agent: userAgent,
      event_id: eventId,
      event_source_url: url,
    }),
  })
}

export const postFacebookCapiFormSubmit = (eventId: string) => {
  const userAgent: string = navigator.userAgent
  const url: string = location.href

  return callAPIWithNoContent(`${basePath}/facebook_events/form_submit`, {
    method: 'POST',
    body: JSON.stringify({
      user_agent: userAgent,
      event_id: eventId,
      event_source_url: url,
    }),
  })
}
