import { host } from '../url'
import * as next from 'next'
import cookies from 'next-cookies'
import { FORMAT_HTTP_HEADERS } from 'opentracing'
import { tracer } from '../datadog'

export const basePath = `${host}/api`

const creds = 'Basic ' + Buffer.from('dever:devdev').toString('base64')
const withBasicAuth = process.env.CLOUDFRONT_HOST !== 'https://initial.inc'

const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'User-Agent': 'penguin/1.0',
}

// Remove me
const credsPrd = 'Basic ' + Buffer.from('ami:entrepedia').toString('base64')
export const headers = withBasicAuth
  ? {
      ...baseHeaders,
      Authorization: creds,
    }
  : {
      ...baseHeaders,
      Authorization: credsPrd,
    }

const isServerSide = () => {
  return typeof window === 'undefined'
}
export class ApiError extends Error {
  response: Response

  constructor(response: Response, ...params: any[]) {
    super(...params)

    this.response = response
  }
}

export const callAPIRaw = (url: string, options: RequestInit = {}) => {
  let span: any = null
  let childSpan: any = null
  let actualHeaders = headers

  if (isServerSide()) {
    span = tracer.scope().active() // アクティブな（親）Spanを取得
    if (span) {
      childSpan = tracer.startSpan('api_call', { childOf: span }) // 新しい子スパンを作成
      tracer.inject(childSpan, FORMAT_HTTP_HEADERS, actualHeaders) // 現在のSpan情報をヘッダーに注入
    }
  }

  return fetch(url, {
    headers: actualHeaders,
    mode: 'cors',
    ...options,
  }).then((res: Response) => {
    if (res.ok) {
      if (isServerSide() && childSpan) {
        childSpan.finish()
      }
      return res
    }

    const exception = new ApiError(
      res,
      `Request failed: ${res.status} url: ${url} method: ${
        options.method ? options.method : 'GET'
      }}`
    )
    if (isServerSide() && childSpan) {
      childSpan.setTag('error', true)
      childSpan.log({
        event: 'error',
        'error.object': exception,
        message: exception.message,
        stack: exception.stack,
      })
      childSpan.finish()
    }
    throw exception
  })
}

export const callAPI = <T extends any>(
  url: string,
  options?: RequestInit
): Promise<T> =>
  callAPIRaw(url, options)
    .catch((error) => {
      throw error
    })
    .then((res) => {
      return res.json()
    })

export const callAPIWithNoContent = (
  url: string,
  options?: RequestInit
): Promise<null> =>
  callAPIRaw(url, options)
    .catch((error) => {
      throw error
    })
    .then(() => null)

// NOTE: fetch APIはクロスドメイン制約により、ブラウザで実行した場合
// credentials=includeにしてもcookieヘッダーは無視される。
// ただ、Node.js上では制約はないため、SSR時に必要となる。
export const callAuthAPI = <T extends any>(
  ctx: next.NextPageContext,
  url: string
): Promise<T> => {
  const { _session_id: session } = cookies(ctx)
  const headersWithCookie = session
    ? { ...headers, cookie: `_session_id=${session}` }
    : headers
  return callAPIRaw(url, {
    headers: headersWithCookie,
    credentials: 'include',
  })
    .catch((error) => {
      throw error
    })
    .then((res) => {
      return res.json()
    })
}
