import {
  format,
  parseISO,
  formatDistanceStrict,
  isWithinInterval,
  subHours,
} from 'date-fns'
import ja from 'date-fns/locale/ja'

export const dateFormatPattern = 'yyyy/MM/dd'

// 非推奨: INITIAL日付フォーマットはスラッシュ区切りの yyyy/MM/dd です。
export const dateHyphenFormatPattern = 'yyyy-MM-dd'

// 非推奨: INITIAL日付フォーマットはスラッシュ区切りの yyyy/MM/dd です。
export const formatDate = (t: string, as: string = dateHyphenFormatPattern) => {
  // （一時的な処置です）formatDateを二重で呼び出す箇所があると、parseISOでエラーになるので、
  // parseISO はスラッシュ区切りの日付をパースできないので、ハイフン区切りに変換してからパースする
  try {
    const date = parseISO(t.replace(/\//g, '-'))
    return format(date, as, {
      locale: ja,
    })
  } catch (e: unknown) {
    return ''
  }
}

// 非推奨: INITIAL日付フォーマットはスラッシュ区切りの yyyy/MM/dd です。
export const convertToDateString = (
  date: Date | number,
  as: string = dateHyphenFormatPattern
) => {
  try {
    return format(date, as, {
      locale: ja,
    })
  } catch (e: unknown) {
    return ''
  }
}

export const dateInJST = (): Date => {
  const d = new Date()
  d.setTime(d.getTime() + (d.getTimezoneOffset() + 9 * 60) * 60 * 1000)
  return d
}

export const convertToJst = (date: string | undefined | null) => {
  if (date === undefined || date === null) return
  const d = new Date(date)
  return format(d, 'yyyy/MM/dd')
}

export const convertToJstWithTime = (date: string | undefined | null) => {
  if (date === undefined || date === null || date.length === 0) return
  const d = new Date(date.replace(' ', 'T').replace(' UTC', 'Z'))
  return format(d, 'yyyy/MM/dd HH:mm')
}

export const unixtimeUTC = (): number => {
  const date = new Date()
  const UTCseconds = date.getTime() + date.getTimezoneOffset() * 60 * 1000
  return UTCseconds
}

export const getCurrentTime = () => {
  return Math.round(new Date().getTime() / 1000)
}

export const isInADay = (unixtime: number) => {
  return getCurrentTime() - unixtime > 24 * 60 * 60
}

export const formatDateByDistanceToNow = (t: string) => {
  const now = new Date()
  const inputDate = parseISO(t)
  const isIn24hours = isWithinInterval(inputDate, {
    start: subHours(now, 24),
    end: now,
  })

  if (isIn24hours) {
    return formatDistanceStrict(inputDate, now, {
      addSuffix: true,
      locale: ja,
      roundingMethod: 'floor',
    })
  }

  return formatDate(t, 'yyyy/MM/dd')
}
