import React, { useCallback, useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import 'plume-ui/dist/index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'nprogress/nprogress.css'
import 'simplebar-react/dist/simplebar.min.css'
import 'scroll-behavior-polyfill'
import { GlobalStyles } from '@/components/tokens/GlobalStyles'
import { getUser } from '@/lib/api/auth'
import { User } from '../types'
import { pushEventToGTM, setupGoogleTagManager } from '@/lib/gtm'
import { setupIntercom } from '@/lib/intercom'
import { AuthContext } from '../contexts/AuthContext'
import { ModalContext } from '../contexts/ModalContext'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { setupAppcues } from '@/lib/appcues'
import { setupFacebookCapi } from '@/lib/facebookCapi'
import { setupFacebookPixel } from '@/lib/facebookPixel'
import { v4 as uuid } from 'uuid'
import '../public/static/style/blur-background-image.css'
import { TooltipWrapper } from 'plume-ui'
import { createGuest, isLoggedIn } from '@/lib/user'
import '../public/static/icomoon/style.css'
import FullscreenWhiteOverlay from '@/components/common/FullscreenWhiteOverlay'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SAVED_COLUMN_SETTING } from '@/lib/tracking'
import { NoSsr } from '@/components/common/NoSsr'
import { setupDatadogRum } from '@/lib/datadog_rum'

export type PageProps = {
  user: User
}

function App({ Component, pageProps }: AppProps<PageProps>) {
  const [user, setUser] = useState(createGuest())
  const [loading, setLoading] = useState(true)
  const [countModals, setCountModals] = useState(0)
  const router = useRouter()

  useEffect(() => {
    getUser()
      .then((currentUser) => {
        if (JSON.stringify(currentUser) !== JSON.stringify(user)) {
          setUser(currentUser)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  useEffect(() => {
    if (!loading) {
      const path = router.asPath
      setupGoogleTagManager(user)
      setupIntercom(user, path)
      setupAppcues(user)
      // enterprise user でログインしている場合のみ datadogRum を初期化
      if (isLoggedIn(user)) {
        setupDatadogRum()
      }

      try {
        const columnSetting = localStorage.getItem('column_setting')
        // GA4 にカラム設定情報を送信, 保存されていなかったら null を送信
        pushEventToGTM(user, SAVED_COLUMN_SETTING, columnSetting)
      } catch (e) {
        // pass
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading])

  useEffect(() => {
    const eventId = uuid()
    setupFacebookCapi(eventId)
    setupFacebookPixel(eventId, window, document)

    NProgress.configure({ showSpinner: false })
    Router.events.on('routeChangeStart', () => {
      NProgress.start()
    })
    Router.events.on('routeChangeComplete', () => {
      NProgress.done()
      ;(window as any).Appcues.page()
    })
    Router.events.on('routeChangeError', () => {
      NProgress.done()
    })
  }, [])

  useEffect(() => {
    if (countModals > 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [countModals])

  const onChangeModalVisibility = useCallback((visibility: boolean) => {
    if (visibility) {
      setCountModals((prev) => prev + 1)
    } else {
      // 0より小さくならないように
      setCountModals((prev) => Math.max(prev - 1, 0))
    }
  }, [])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY!}
      useEnterprise={true}
      language="ja"
    >
      <GlobalStyles />
      <ModalContext.Provider value={onChangeModalVisibility}>
        <AuthContext.Provider value={user}>
          {loading && <FullscreenWhiteOverlay />}
          <Component {...pageProps} />
        </AuthContext.Provider>
      </ModalContext.Provider>
      <NoSsr>
        <TooltipWrapper />
      </NoSsr>
    </GoogleReCaptchaProvider>
  )
}

export default App
