import { datadogRum } from '@datadog/browser-rum'

export const setupDatadogRum = () => {
  if (process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED === 'true') {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID!,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
      site: process.env.NEXT_PUBLIC_DATADOG_SITE!,
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE!,
      env: process.env.NEXT_PUBLIC_DATADOG_ENV!,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100, // 100%の場合は全てのセッションがリプレイまで記録される
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls: [
        process.env.NEXT_PUBLIC_DATADOG_ALLOWED_TRACING_URLS!,
      ],
      defaultPrivacyLevel: 'mask-user-input',
    })
  }
}
